<template>
  <v-container class="px-8">
    <v-row class="mb-2">
      <h3 class="text-h3 primary--text">
        {{ title }}
      </h3>
    </v-row>
    <v-row >
      <p><a @click="toggleExpand">{{ expandSymbol }}</a></p>
      <p :class="{ 'overflow-ellipsis': !expanded }">{{ content }}</p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ExpandableCardSection",
  props: ["title", "content"],
  data: () => {
    return {
      expanded: false,
    };
  },
  computed: {
    expandSymbol() {
      return (this.expanded && "Menos -") || "Más [+]";
    },
  },
  methods: {
    toggleExpand(e) {
      e.stopPropagation();
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped>
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
}
</style>

<template>
  <v-dialog
    :persistent="true"
    v-model="active"
    @keydown.esc="emitClose"
    width="auto"
    max-width="700px"
    class="overflow-overlay"
  >
    <v-card class="pa-0 rounded-lg">
      <v-card-title class="solid-heading rounded-t-lg my-0 py-6 px-2 px-sm-10">
        <div
          class="card-title d-flex text-h3 font-weight-semibold white--text mt-2 text-left"
        >
          <div class=" my-auto">{{ drugName }}</div>
        </div>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-container class="pa-0" style="background: #381273">
          <v-row>
            <v-col cols="8" class="" align-self="center">
              <v-row
                v-if="drug.drugResult.result >= 0"
                class="px-12 pt-4 white--text"
              >
                <h3 class="text-h3 mb-0">{{ $t("Recomendacion") }}</h3>
                <v-spacer></v-spacer>
              </v-row>
              <v-row
                v-if="drug.drugResult.result >= 0"
                class="px-12 pb-4 white--text"
              >
                {{ $t(`drug_result_${recommendationIndex}`) }}<br />{{
                  $t(`drug_recommendation_${recommendationIndex}`)
                }}
              </v-row>
              <v-row v-else class="px-12 pb-4 white--text">
                {{ $t(`sin_interacciones`) }}
              </v-row>
            </v-col>
            <v-col cols="" align-self="center">
              <ColorScore :score="drug.drugResult.result" class="mb-4" />
            </v-col>
          </v-row>
        </v-container>

        <!-- FARMACOGENETICA -->
        <v-row class="mt-4 px-10">
          <h4 class="text-h3 primary--text mb-2">
            {{ $t("farmacogenetica") }}
          </h4>
        </v-row>
        <v-row class="px-16">
          <h4 class="mr-2 primary--text">{{ $t("Substrato de") }}</h4>
          <div class="d-flex flex-wrap">
            <div v-for="s in substratOf" :key="s.gene" class="mr-8">
              {{ s.gene }}
              <v-badge :color="s.color" class="ml-2"></v-badge>
            </div>
          </div>
        </v-row>
        <v-row class="px-16">
          <h4 class="mr-2 primary--text ">{{ $t("Inhibe") }}</h4>
          <div class="d-flex flex-wrap">
            <div class="mr-5" v-for="el in drug.source.Inhibits" :key="el">
              {{ el }}
            </div>
          </div>
        </v-row>
        <v-row class="px-16 mb-8">
          <h4 class="mr-2 primary--text">{{ $t("Induce") }}</h4>
          <div class="d-flex flex-wrap">
            <div class="mr-5" v-for="el in drug.source.Induces" :key="el">
              {{ el }}
            </div>
          </div>
        </v-row>

        <!-- NOMBRES COMERCIALES -->
        <ExpandableCardSection
          :title="$t('nombres comerciales')"
          :content="drug.source.Brandnames.join(', ')"
        />

        <v-divider></v-divider>

        <!-- ALTERNATIVAS POR CATEGORÍAS -->
        <v-row class="px-10 d-block">
          <h3 class="text-h3 primary--text">
            {{ $t("alternativas por categoria") }}
          </h3>

          <v-row
            v-for="c in drug.source.EPG_drug_categories"
            :key="c"
            class="px-6 my-2 d-block"
          >
            <h4 class="primary--text ">
              {{ drugCategoryData(c)[$i18n.locale] }}
            </h4>

            <p>
              {{ alternatives(c) }}
            </p>
          </v-row>
        </v-row>

        <v-divider></v-divider>

        <v-row class="px-10">
          <div v-if="indications" class="">
            <h3 class="text-h3 primary--text">
              {{ $t("indicaciones") }}
            </h3>
            <p>
              {{ indications.join(". ") }}
            </p>
          </div>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-around py-6 mt-8" fluid>
        <v-btn color="darkprimary" rounded class="" @click="emitClose">
          {{ $t("Cerrar") }}
        </v-btn>
        <v-btn
          v-if="drug.source.Has_EPG"
          color="primary"
          elevation=""
          rounded
          depressed
          @click="
            $router.push({
              name: 'Explorer.drug_detail',
              params: { id: drug.source.id }
            })
          "
        >
          {{ $t("more_info") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ExpandableCardSection from "@/components/mylogy/ExpandableCardSection.vue";
import ColorScore from "@/components/mylogy/ColorScore.vue";
import { mapGetters } from "vuex";
export default {
  name: "DrugResultDialog",
  components: {
    ExpandableCardSection,
    ColorScore
  },
  props: ["active", "drug"],
  computed: {
    ...mapGetters("drugCategories", ["drugCategoryData"]),
    ...mapGetters("pgxDrugResults", ["pgxGreenDrugsSlugs"]),
    ...mapGetters("drugs", ["drugData"]),
    drugName() {
      return this.drug.source["Drug_Name_" + this.$i18n.locale.toUpperCase()];
    },
    indications() {
      return this.drug.source["DC_Ind_" + this.$i18n.locale.toUpperCase()];
    },
    offlabelUse() {
      return this.drug.source["DC_Off_" + this.$i18n.locale.toUpperCase()];
    },
    recommendationIndex() {
      if (this.drug.drugResult.result >= 0.75) {
        return "2";
      } else if (this.drug.drugResult.result >= 0.5) {
        return "1";
      } else if (this.drug.drugResult.result >= 0.25) {
        return "0";
      }
      return "3";
    },
    substratOf() {
      return Object.keys(this.drug.drugResult.source).map(k => {
        return { gene: k.split("*")[0], color: this.drug.drugResult.source[k] };
      });
    }
  },
  methods: {
    alternatives(c) {
      let self = this;
      return this.drugCategoryData(c)
        .drugs.filter(c => {
          return self.pgxGreenDrugsSlugs.includes(c);
        })
        .map(c => {
          return self.drugData(c)[self.$i18n.locale];
        })
        .join(", ");
    },
    emitClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.card-title {
  line-height: 1em !important;
}
.border-left {
  border-left: solid #381273;
}
.v-card .v-card__actions {
  background: #ffffff00;
  box-shadow: 0 5px 30px -20px inset #310ab1;
}
</style>
